<template>

  <div>

    <user-list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :role-options="roleOptions"
      :plan-options="planOwnerOptions"
      @refetch-data="refetchData"
    />

    <!-- Filters -->
    <users-list-filters
      :role-filter.sync="roleFilter"
      :plan-owner-filter.sync="planOwnerFilter"
      :status-filter.sync="statusFilter"
      :role-options="roleOptions"
      :plan-owner-options="planOwnerOptions"
      :status-options="statusOptions"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
            <b-button
              v-if="$ability.can('export', 'user')"
              variant="primary"
              class="pl-2 ml-50"
              :disabled="isExporting"
              @click="exportDocuments()"
            >
              <b-spinner
                v-if="isExporting"
                small
                type="grow"
              />
              <feather-icon
                v-else
                icon="UploadIcon"
                class="mr-50"
              />
              {{ isExporting ? 'exporting...' : 'Export' }}
            </b-button>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-button
                v-if="false"
                variant="primary"
                @click="isAddNewUserSidebarActive = true"
              >
                <span class="text-nowrap">Add User</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        :small="false"
        class="position-relative"
        :items="fetchUsers"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: User -->
        <template #cell(name)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.profile.image_url"
                :text="avatarText(data.item.name)"
                :variant="`light-${resolveUserRoleVariant(get(data, 'item.roles.0.name'))}`"
                :to="{ name: 'user-edit', params: { hashid: data.item.hashid } }"
              />
            </template>
            <b-link
              :to="{ name: 'user-edit', params: { hashid: data.item.hashid } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.name }}
            </b-link>
            <small class="text-muted">#{{ data.item.username }}</small>
          </b-media>
        </template>

        <template #cell(email)="data">
          <small>{{ data.value }}</small>
        </template>

        <template #cell(user_profiles|user_profiles.grade.name)="data">
          <small>{{ get(data, 'item.profile.grade.name') }}</small>
        </template>

        <!-- Column: Role -->
        <template #cell(role)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveUserRoleIcon(get(data, 'item.roles.0.name'))"
              size="18"
              class="mr-50"
              :class="`text-${resolveUserRoleVariant(get(data, 'item.roles.0.name'))}`"
            />
            <span class="align-text-top text-capitalize">{{ get(data, 'item.roles.0.name') }}</span>
          </div>
        </template>

        <template #cell(plan_owners|plan_owners.name)="data">
          <b-badge
            v-if="!data.item.plan_owner.name"
            variant="light-secondary"
          >
            NULL
          </b-badge>
          <small v-else>{{ data.item.plan_owner.name }}</small>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-if="false"
              :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
            >
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Details</span>
            </b-dropdown-item>

            <b-dropdown-item :to="{ name: 'user-edit', params: { hashid: data.item.hashid } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item v-if="false">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import { get } from 'lodash'
import documentStoreModule from '@/views/eplan/documents/documentStoreModule'
import Swal from 'sweetalert2'
import { httpGetFile } from '@/libs/axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import UsersListFilters from './UsersListFilters.vue'
import userStoreModule from '../userStoreModule'
import UserListAddNew from './UserListAddNew.vue'
import useUsersList from './useUsersList'

export default {
  components: {
    UsersListFilters,
    UserListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'
    const DOCUMENT_STORE_MODULE_NAME = 'app-document'
    const toast = useToast()

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)
    if (!store.hasModule(DOCUMENT_STORE_MODULE_NAME)) store.registerModule(DOCUMENT_STORE_MODULE_NAME, documentStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
      if (store.hasModule(DOCUMENT_STORE_MODULE_NAME)) store.unregisterModule(DOCUMENT_STORE_MODULE_NAME)
    })

    const isAddNewUserSidebarActive = ref(false)
    const isExporting = ref(false)

    const roleOptions = [
      { label: 'SuperAdmin', value: 'superadmin' },
      { label: 'Administrator', value: 'administrator' },
      { label: 'Editor', value: 'editor' },
      { label: 'Viewer', value: 'viewer' },
    ]

    const statusOptions = [
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
      { label: 'Expired', value: 'expired' },
      { label: 'Suspended', value: 'suspended' },
    ]

    const {
      getFetchPayload,
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planOwnerFilter,
      statusFilter,
      planOwnerOptions,
    } = useUsersList()

    const exportDocuments = () => {
      // check if too many records selected to export
      if (totalUsers.value > 5000) {
        // eslint-disable-next-line no-alert
        Swal.fire({
          title: 'Error! too many records selected for export',
          html: `There are ${totalUsers.value} users currently selected.<br /><br />Please try again with some filter and keep it below 5000 records.`,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })

        return
      }

      isExporting.value = true
      store
        .dispatch('app-user/fetchUsers', getFetchPayload(1))
        .then(response => {
          httpGetFile({
            blob: response.data,
            fileType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            fileName: 'eplan-users-export.xlsx',
          })
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error exporting users',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }).finally(() => {
          isExporting.value = false
        })
    }

    return {
      get,
      isExporting,
      exportDocuments,

      // Sidebar
      isAddNewUserSidebarActive,

      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      roleOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planOwnerFilter,
      statusFilter,
      planOwnerOptions,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
