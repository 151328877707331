// import axios from '@axios'
import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchUsers(ctx, { params = {}, config = {} }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/user', { params, ...config })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUser(ctx, { hashid, params }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/user/${hashid}`, { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/user/users', { user: userData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateUser(ctx, { hashid, payload }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/user/${hashid}`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAdministrators(ctx, { planOwnerId, idOnly = 0 }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/user/get-administrators/${planOwnerId}/${idOnly}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
