<template>
  <div>

    <!-- Media -->
    <b-media class="mb-2">
      <template #aside>
        <b-avatar
          ref="previewEl"
          :src="form.profile.image_url"
          :text="avatarText(form.fullName)"
          :variant="`light-${resolveUserRoleVariant(form.role)}`"
          size="140px"
          rounded
        />
      </template>
      <h4 class="mb-1 mt-2">
        {{ form.name }}
        <div class="mt-1">
          <small>#{{ form.username }}</small>
        </div>
      </h4>
    </b-media>

    <!-- User Info: Input Fields -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
      slim
    >
      <b-form
        @submit.prevent="handleSubmit(onSave)"
        @reset.prevent="resetForm"
      >
        <b-row>

          <!-- Field: Username -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Username"
              label-for="username"
            >
              <b-form-input
                id="username"
                v-model="form.username"
                disabled
              />
            </b-form-group>
          </b-col>

          <!-- Field: Full Name -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Name"
              label-for="full-name"
            >
              <b-form-input
                id="full-name"
                v-model="form.name"
                disabled
              />
            </b-form-group>
          </b-col>

          <!-- Field: Email -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Email"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="form.email"
                type="email"
                disabled
              />
            </b-form-group>
          </b-col>

          <!-- Field: Designation -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Designation"
              label-for="designation"
            >
              <b-form-input
                id="designation"
                v-model="form.profile.designation"
                disabled
              />
            </b-form-group>
          </b-col>
        </b-row>

        <hr>

        <b-row>
          <!-- Field: Status -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Status"
              label-for="user-status"
            >
              <v-select
                v-model="form.status"
                :disabled="!$ability.can('manage', 'user')"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="user-status"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Role -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              rules="required"
              name="Role"
            >
              <b-form-group
                label="User Role"
                label-for="user-role"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="form.roles"
                  :disabled="!$ability.can('manage', 'user')"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="roleOptions"
                  :reduce="val => val.value"
                  :clearable="true"
                  :selectable="() => form.roles <= 1"
                  input-id="user-role"
                  multiple
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Plan Owner -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              :rules="{required: form.roles.length > 0 && ['editor'].includes(form.roles[0])}"
              name="Plan Owner"
            >
              <b-form-group
                label="Plan Owner"
                label-for="user-plan-owner"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="form.plan_owner_id"
                  :disabled="!$ability.can('manage', 'user')"
                  :options="planOwners"
                  :reduce="val => val.id"
                  :clearable="true"
                  label="plan_owner"
                  input-id="user-plan-owner"
                  @input="onPlanOwnerChanged"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Administrators -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              :rules="{required: form.roles.length > 0 && ['editor'].includes(form.roles[0])}"
              name="Direct Administrator(s)"
            >
              <b-form-group
                label="Direct Administrator(s)"
                label-for="user-direct-admins"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="form.administrator_ids"
                  :disabled="!$ability.can('manage', 'user') || !['editor'].includes(form.roles[0]) || !form.plan_owner_id"
                  :options="filteredPlanOwnerAdministrators"
                  :reduce="val => val.id"
                  :clearable="true"
                  label="name"
                  input-id="user-direct-admins"
                  multiple
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <!-- Action Buttons -->
        <b-row class="mt-2 text-right">
          <b-col>
            <b-button
              variant="outline-secondary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="onCancel"
            >
              Cancel
            </b-button>
            <b-button
              variant="primary"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              type="submit"
            >
              Save Changes
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BFormInvalidFeedback,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref, watch } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required, alphaNum, email } from '@validations'
import router from '@/router'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { useToast } from 'vue-toastification/composition'
import { get } from 'lodash'
import documentStoreModule from '@/views/eplan/documents/documentStoreModule'
import useUsersList from '../users-list/useUsersList'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormInvalidFeedback,
    vSelect,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
    }
  },
  setup(props) {
    // Register module
    const DOCUMENT_STORE_MODULE_NAME = 'app-document'
    if (!store.hasModule(DOCUMENT_STORE_MODULE_NAME)) store.registerModule(DOCUMENT_STORE_MODULE_NAME, documentStoreModule)

    const toast = useToast()

    const { resolveUserRoleVariant } = useUsersList()

    const userData = ref(props.userData)

    const form = ref({
      administrator_ids: [],
      roles: [],
      profile: {},
      ...userData.value,
    })

    form.value.roles = get(userData.value, 'roles.0') ? [get(userData.value, 'roles.0.name')] : []

    watch(userData.value, () => {
      form.value = {
        ...form.value,
        ...userData.value,
      }
      form.value.roles = get(userData.value, 'roles.0') ? [get(userData.value, 'roles.0.name')] : []
    }, { deep: true })

    const roleOptions = [
      { label: 'Viewer', value: 'viewer' },
      { label: 'Editor', value: 'editor' },
      { label: 'Administrator', value: 'administrator' },
      { label: 'SuperAdmin', value: 'superadmin' },
    ]

    const statusOptions = [
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
      { label: 'Expired', value: 'expired' },
      { label: 'Suspended', value: 'suspended' },
    ]

    const planOwners = ref([])
    const planOwnerAdministrators = ref([])
    const filteredPlanOwnerAdministrators = ref([])

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      form.value.avatar = base64
    })

    const onSave = () => {
      console.log(form.value)
      const payload = { ...form.value }
      console.log(payload)
      store.dispatch('app-user/updateUser', { hashid: form.value.hashid, payload })
        .then(response => {
          // redirect
          router.push({ name: 'user-list' })

          // toast
          toast({
            component: ToastificationContent,
            props: {
              title: 'User saved!',
              text: response.data.message,
              icon: 'SaveIcon',
              variant: 'success',
            },
          })
        })
        .catch(({ response }) => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error updating document',
              text: response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const resetuserData = () => {
      form.value = JSON.parse(JSON.stringify({ profile: {} }))
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    const onCancel = () => {
      router.push({ name: 'user-list' })
    }

    const onPlanOwnerChanged = () => {
      filteredPlanOwnerAdministrators.value = planOwnerAdministrators.value.filter(po => po.plan_owner_id === form.value.plan_owner_id)
    }

    // fetch plan owner
    store
      .dispatch('app-document/fetchDocumentMeta', {
        field: 'plan_owner_id',
      })
      .then(response => {
        planOwners.value = get(response, 'data.payload.plan_owner_id')
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching documents' meta",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })

    // fetch Administrators in the system
    store
      .dispatch('app-user/fetchAdministrators', {
        planOwnerId: 0,
        idOnly: 0,
      })
      .then(response => {
        planOwnerAdministrators.value = get(response, 'data.payload')
        onPlanOwnerChanged()
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching Administrator list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })

    return {
      form,
      resolveUserRoleVariant,
      avatarText,
      roleOptions,
      statusOptions,
      planOwners,
      planOwnerAdministrators,
      filteredPlanOwnerAdministrators,
      onPlanOwnerChanged,

      refFormObserver,
      getValidationState,
      resetForm,

      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,

      onSave,
      onCancel,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
