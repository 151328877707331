import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchDocuments(ctx, { params = {}, config = {} }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/document', { params, ...config })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchDocument(ctx, { hashid, params }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/document/${hashid}`, { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteDocument(ctx, hashid) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/document/${hashid}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getFile(ctx, { hashid, preview = 0 }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/document/get-file/${hashid}/${preview}`, { responseType: 'blob' })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchDocumentMeta(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/document/meta', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    uploadDocument(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/document/create', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateDocument(ctx, { hashid, payload }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/document/${hashid}`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    searchKeywordTags(ctx, search) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/document/search-keyword-tags/${search}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    generatePDFPreview(ctx, hashid) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/document/generate-pdf-preview/${hashid}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
