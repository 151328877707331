import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { get } from 'lodash'

export default function useUsersList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'name', sortable: true },
    { key: 'email', sortable: true },
    { key: 'role', sortable: false },
    { key: 'plan_owners|plan_owners.name', label: 'Plan Owner', sortable: true },
    {
      key: 'user_profiles|user_profiles.grade.name',
      label: 'Designation',
      formatter: title,
      sortable: false,
    },
    { key: 'status', sortable: true },
    { key: 'actions' },
  ]
  const perPage = ref(25)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref([])
  const planOwnerFilter = ref(null)
  const statusFilter = ref([])
  const planOwnerOptions = ref([])

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, roleFilter, planOwnerFilter, statusFilter], () => {
    refetchData()
  })

  const getFetchPayload = (isExport = 0) => {
    const search = searchQuery.value

    return {
      params: {
        roles: roleFilter.value,
        plan_owner_ids: planOwnerFilter.value,
        statuses: statusFilter.value,
        with: 'profile;profile.grade;roles;planOwner:id,name',
        search,
        search_join: searchQuery.value ? 'or' : 'and',
        per_page: perPage.value,
        page: currentPage.value,
        order_by: sortBy.value,
        sort_dir: isSortDirDesc.value ? 'desc' : 'asc',
        export: isExport ? 1 : 0,
      },
      config: isExport ? { responseType: 'blob' } : {},
    }
  }

  const fetchUsers = (ctx, callback) => {
    store
      .dispatch('app-user/fetchUsers', getFetchPayload())
      .then(response => {
        const users = response.data.payload.data

        callback(users)
        totalUsers.value = response.data.payload.total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })

    store
      .dispatch('app-document/fetchDocumentMeta', {
        field: 'plan_owner_id',
      })
      .then(response => {
        planOwnerOptions.value = get(response, 'data.payload.plan_owner_id')
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching documents' meta",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'viewer') return 'primary'
    if (role === 'editor') return 'info'
    if (role === 'administrator') return 'success'
    if (role === 'superadmin') return 'danger'
    if (role === 'systemadmin') return 'danger'
    return 'secondary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'viewer') return 'UserIcon'
    if (role === 'editor') return 'EditIcon'
    if (role === 'administrator') return 'KeyIcon'
    if (role === 'superadmin') return 'SettingsIcon'
    if (role === 'systemadmin') return 'ServerIcon'
    return 'UserXIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 'expired') return 'warning'
    if (status === 'suspended') return 'danger'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  return {
    getFetchPayload,
    fetchUsers,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,

    // Extra Filters
    roleFilter,
    planOwnerFilter,
    statusFilter,
    planOwnerOptions,
  }
}
